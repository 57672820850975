import * as React from 'react'
import { graphql } from 'gatsby'
import styled from "@emotion/styled"

const Buttonwrap = styled.div`
    width: 700px;
    margin: 0px auto 60px auto;
    display: block;
    padding-bottom: 26px;
    a {
        font-family: 'Inter', sans-serif;
        font-size: 16px;
        letter-spacing: 2px;
        font-weight: 700;
        text-transform: uppercase;
        color: rgba(255,255,255,1);
        display: block;
        margin: 0px auto;
        width: 350px;
        padding: 10px 0px 10px 0px;
        border: none;
        background-color: rgba(0,0,0,0.4);
        border-radius: 12px;
        box-shadow: 1px 1px 5px rgb(0 0 0 / 15%);
        text-align: center;
        transition: all 0.2s ease;
        &:hover {
            background-color: #f15a24;
            box-shadow: 1px 1px 5px rgb(0 0 0 / 30%);
        }
    }
    @media (max-width: 676px) {
      width: auto;
    }
`

export const Button = ({ slice }) => {

  return (
    <Buttonwrap>
        {slice.primary.link.url && <a href={slice.primary.link.url} target="_blank" rel="noreferrer">{slice.primary.link_title.text}</a>}
    </Buttonwrap>
  )
}

export const query = graphql`
fragment FullProjectDataBodyButton on PrismicFullProjectDataBodyButton {
  id
  slice_type
  primary {
    link {
      url
    }
    link_title {
      text
    }
  }
}
`
