import * as React from 'react'
import { graphql } from 'gatsby'
import ReactPlayer from 'react-player/lazy'
import styled from "@emotion/styled"

const Videowrap = styled.div`
margin: 0 auto;
padding: 60px 0px 40px 0px;
width: 60vw;
@media (max-width: 676px) {
  width: auto;
  padding: 0px 20px 40px 20px;
}
video {
    border: solid 1px rgba(0,0,0,0.1);
}
`

export const Video = ({ slice }) => {
  return (
        <Videowrap>
            {slice.primary.video_link && <ReactPlayer
        url={slice.primary.video_link.url}
        muted
        volume={0}
        loop
        playing
        playsinline
        className='react-player left-origin'
        height='100%'
        width='100%'
        />
        }
        </Videowrap>
  )
}

export const query = graphql`
fragment FullProjectDataBodyVideo on PrismicFullProjectDataBodyVideo {
  id
  slice_type
  primary {
    video_link {
      url
    }
  }
}
`
