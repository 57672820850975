import { Text } from './text'
import { Image } from './image'
import { ImageGrid } from './imagegrid'
import { Video } from './video'
import { Button } from './button'
import { Footer } from './footer'

export const components = {
  text: Text,
  image: Image,
  image_grid: ImageGrid,
  video: Video,
  button: Button,
  footer: Footer
}