import React from 'react'
import { Link } from 'gatsby'
import styled from "@emotion/styled"
import { ArrowRight, ArrowLeft } from "phosphor-react";

const Pager = styled.nav`
&.quick .prev svg {
    border-right: solid 1px rgba(0,0,0,0.33);
}
&.quick .next svg {
    border-left: solid 1px rgba(0,0,0,0.33);
}
&.quick > div {
    a {
        &:hover svg {
            border-color: #fff;
            fill: white;
        }
    }
}
> div {
    position: absolute;
    top: 50vh;
    transform: translateY(-50%);
    z-index: 95;
    display: inherit;
    width: inherit;
    border: none;
    box-sizing: inherit;
    a {
        border: none;
        color: rgba(255,255,255,1);
        padding: 10px 0px;
        background-color: rgba(50,50,50,0);
        display: block;
        transition: all 0.3s ease-out;
        font-family: "Freight Big Black Regular";
        line-height: 1em;
        font-size: 1.5rem;
        &:hover {
            background-color: rgba(50,50,50,0.95);
            color: rgba(255,255,255,1);
            @media (max-width: 676px) {
                background: none;
                color: #000;
            }    
        }
        &:hover div {
            opacity: 1;
            @media (max-width: 676px) {
                display: none;
                visibility: hidden;
                opacity: 0;
            }
        }
        svg {
            display: inline-block;
            vertical-align: middle;
        }
        > div {
            display: inline-block;
            opacity: 0;
            width: 320px;
            vertical-align: middle;
            @media (max-width: 676px) {
                width: 0;
            }
        }
    }
}
`
const Previous = styled.div`
    left: 0px;
    transition: all 0.3s ease;
    a {
        text-align: left;
        svg {
            padding: 0 0.5vw;
            margin-right: 1vw;
        }
    }
`
const Next = styled.div`
    right: 0px;
    transition: all 0.3s ease;
    a {
        margin: inherit;
        text-align: right;
        svg {
            padding: 0 0.5vw;
            margin-left: 1vw;
        }
    }
`

const Pagernav = ({ type, nextLink, nextTitle, previousLink, previousTitle, footer }) => {
    const quick = type === 'quick' ? '#999' : '#fff'

    return (
        <Pager className={type}>
            {previousLink && <Previous className="prev"><Link to={previousLink}><ArrowLeft size={40} color={quick} /><div>{previousTitle}</div></Link></Previous>}
            {nextLink && <Next className="next"><Link to={nextLink}><div>{nextTitle}</div><ArrowRight size={40} color={quick} /></Link></Next>}
        </Pager>
    )
}

export default Pagernav