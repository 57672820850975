import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'
import styled from "@emotion/styled"

const Textwrap = styled.div`
width: 60vw;
margin: 0px auto;
padding: 0px 0px 40px 0px;
text-align: left;
@media (max-width: 676px) {
  width: auto;
  padding: 0px 20px 40px 20px;
}
h3 {
  font-family: "Freight Big Black Regular";
  font-size: 3rem;
  line-height: 1em;
  margin-bottom: 26px;
  &::before {
    content: '';
    display: block;
    width: 15%;
    margin: 40px auto 60px 0px;
    border: 0;
    border-top: 1px solid rgba(0,0,0,0.3);
  }
  @media (max-width: 676px) {
    font-size: 24px;
    margin-bottom: 10px;
  }
}
p {
  padding-bottom: 26px;
  color: rgba(0,0,0,.8);
  a {
    border-bottom: solid 3px rgba(0,0,0,0.1);
    font-weight: 450;
    padding-bottom: 3px;
    &:hover {
      color: rgba(0,0,0,1);
      border-bottom-color: rgba(255,222,23,1);
    }
  }
}
li {
  &::before {
    content: '—';
    padding-right: 7px;
  }
}
p, li {
  strong {
    font-weight: 800;
  }
  em {
    border-bottom: solid 1px rgba(0,0,0,0.25);
    font-variation-settings: 'slnt' -8;
  }
}
pre {
  border-left: solid 1px rgba(0,0,0,0.2);
  padding-left: 40px;
  padding-bottom: 26px;
  margin-bottom: 26px;
  color: rgba(0,0,0,.8);
  white-space: pre-line;
}
`

export const Text = ({ slice }) => {

    return (
    <Textwrap>
      <PrismicRichText field={slice.primary.text.richText} />
    </Textwrap>
  )
}

export const query = graphql`
fragment FullProjectDataBodyText on PrismicFullProjectDataBodyText {
  id
  slice_type
  primary {
    text {
      richText
    }
  }
}
`
