import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { PrismicRichText } from '@prismicio/react'
import { AsteriskSimple } from "phosphor-react"
import styled from "@emotion/styled"

const Imagewrap = styled.div`
> div {
    margin: 0px auto;
    padding: 60px 0px 40px 0px;
    position: relative;
    > div {
        &:hover {
            cursor: pointer;
        }
        &::after {
            content: '+';
            background-color: rgb(127,127,127);
            color: #fff;
            font-weight: bold;
            position: absolute;
            bottom: 0;
            right: 0;
            height: 30px;
            width: 30px;
            text-align: center;
            line-height: 30px;
            opacity: 0;
            transition: all 0.3s ease;
        }
        &:hover::after {
            opacity: 1;
        }

    }
    @media (max-width: 676px) {
      width: auto;
      margin: 0px 20px;
    }
}
.medium.large {
    width: 60vw;
}
.large {
    width: 77.5vw;
}
.medium {
    width: 47.5vw;
}
.small {
    width: 37.5vw;
}
@media (max-width: 676px) {
  .medium.large, .large, .medium, .small {
    width: auto;
  }
}
`
const Caption = styled.div`
    width: 60vw;
    text-align: center;
    padding: 0 !important;
    color: rgba(0,0,0,0.5);
    font-size: .9125rem;
    line-height: 1.66em;
    margin-bottom: 100px !important;
    p, svg {
        display: inline;
        vertical-align: middle;
      }
      svg {
        padding-right: 10px;
      }
  `

export const Image = ({ slice }) => {

  return (
    <Imagewrap>
        <div className={slice.primary.size}>
            {slice.primary.image && <GatsbyImage image={slice.primary.image.gatsbyImageData} alt="image" objectFit="contain" data-fancybox="images" />}
        </div>
        {slice.primary.caption.text && <Caption><AsteriskSimple size={14} color="#808080" weight="light" /><PrismicRichText field={slice.primary.caption.richText} /></Caption>}
    </Imagewrap>
  )
}

export const query = graphql`

fragment FullProjectDataBodyImage on PrismicFullProjectDataBodyImage {
    id
    slice_type
    primary {
      size
      image {
        gatsbyImageData
      }
      caption {
          richText
          text
      }
    }
  }
`
