import * as React from 'react'
import { graphql, Link } from 'gatsby'
import { SliceZone } from '@prismicio/react'
import { PrismicRichText } from '@prismicio/react'
import { GatsbyImage } from 'gatsby-plugin-image'
import styled from "@emotion/styled"

import Layout from '../components/layout'
import Pagernav from '../components/pagernav'
import Footernav from '../components/footernav'
import Seo from '../components/seo'

import { components } from '../slices'

const Article = styled.article`
position: relative;
`
const Banner = styled.section`
width: 100%;
max-height: 100vh;
overflow: hidden;
position: relative;
background-color: rgb(200,200,200);
.title {
    position: absolute;
    top: 35%;
    width: 100%;
    color: #fff;
    z-index: 90;
    h1 {
        font-family: "Freight Big Black Regular";
        font-size: 4.5rem;
        line-height: 1em;
        text-align: center;
        margin: 0px auto 10px auto;
        padding: inherit;
        background-color: inherit;
        width: 80%;
        letter-spacing: -1px;
        @media (max-width: 676px) {
          font-size: 3rem;
        }
    }
    h2 {
        font-family: "FreightText Light Italic";
        letter-spacing: 1px;
        font-size: 28px;
        margin: 0px auto;
        width: 80%;
    }
  
}
&.quick {
  background-color: transparent;
  .title {
    position: static;
    padding: 20px 0px 0px 0px;
    margin-top: 160px;
    color: #000;
    h1 {
      padding: 0;
    }
    @media (max-width: 676px) {
      width: 100vw;
      margin-top: 22.5vh;
    }

  }
  }
.bannerimage {
    position: relative;
    width: 100%;
    height: 100%;
    .gatsby-image-wrapper {
        & > div:first-of-type {
            width: 100vw;
            max-width: none !important;
        }
        img {
            object-fit: cover;
            height: 100vh;
            width: 100vw;
        }    
    }
    .shade {
        background-color: rgba(0,0,0,.4);
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0px;
        left: 0px;
        z-index: 10;
    }
  }
}
`
const Articlebody = styled.section`
margin: 0px auto;
padding-top: 120px;
`
const Tags = styled.div`
color: rgba(255,255,255,0.4);
width: 350px;
margin: 60px auto;
padding-top: 20px;
border-top: dotted 1px rgba(255,255,255,0.6);
li {
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 10px;
  margin: 5px 2px;
  display: inline-block;
}
a {
  padding: 7px 12px;
  border: solid 1px rgba(255,255,255,0.2);
  border-radius: 6px;
  background-color: rgba(255,255,255,0.1);
  color: rgba(255,255,255,0.7);
  transition: all 0.3s ease-out;
  &:hover {
    border-color: rgba(255,255,255,0.4);
    color: rgba(255,255,255,1);
    background-color: rgba(255,255,255,0.25);
  }
}
&.quick {
  margin-bottom: 0;
  margin: 10px auto 0 auto;
  color: rgba(255,255,255,0.4);
  width: 350px;
  padding-top: 20px;
  border-top: dotted 1px rgba(255,255,255,0.6);
  li a {
    border-color: rgba(0,0,0,.15);
    color: rgba(0,0,0,0.5);
    &:hover {
      border-color: rgba(0,0,0,.5);
      color: rgba(0,0,0,0.75);
    }
  }
}

}
`

const ProjectTemplate = ({ pageContext, data }) => {
    if (!data) return null
    const doc = data.prismicFullProject.data
    const quickPost = doc.quick_post_ ? 'quick' : ''

    const { next } = pageContext
    const { previous } = pageContext

    const _ = require("lodash")

      return (
        <Layout>
            <Seo title={doc.project_title.text} image={doc.banner_image.gatsbyImageData}/>
            <Pagernav type={quickPost} nextLink={next && next.url} nextTitle={next && next.data.project_title.text} previousLink={previous && previous.url} previousTitle={previous && previous.data.project_title.text} />
            <Article>
                <Banner className={quickPost}>
                    <div className="title">
                        <PrismicRichText field={doc.project_title.richText} />
                        <PrismicRichText field={doc.subtitle.richText} />
                        <Tags className={quickPost}>
                          <ul>
                            <li key={`/section/${_.kebabCase(data.prismicFullProject.tags)}/`}><Link to={`/section/${_.kebabCase(data.prismicFullProject.tags)}/`}>{data.prismicFullProject.tags}</Link></li>
                            {doc.category1.map((item) => (
                            <li key={`/category/${_.kebabCase(item.category)}/`}><Link to={`/category/${_.kebabCase(item.category)}/`}>{item.category}</Link></li>
                            ))}
                          </ul>
                        </Tags>
                    </div>
                    { !quickPost && <div className="bannerimage">
                        <GatsbyImage image={doc.banner_image.gatsbyImageData} />
                        <div className="shade"></div>
                    </div> }
                </Banner>
                <Articlebody>
                    <SliceZone slices={doc.body} components={components} />
                </Articlebody>
            </Article>
            <Footernav nextLink={next && next.url} nextTitle={next && next.data.project_title.text} nextSubtitle={next && next.data.subtitle.text} previousLink={previous && previous.url} previousTitle={previous && previous.data.project_title.text} previousSubtitle={previous && previous.data.subtitle.text} />

        </Layout>
    )
}

export const query = graphql`
query PageQuery($id: String) {
  prismicFullProject(id: {eq: $id}) {
    url
    tags
    data {
      quick_post_
      banner_image {
        gatsbyImageData
      }
      project_title {
        richText
        text
      }
      subtitle {
        richText
      }
      category1 {
        category
      }
      body {
        ... on PrismicSliceType {
          slice_type
        }
        ...FullProjectDataBodyImage
        ...FullProjectDataBodyImageGrid
        ...FullProjectDataBodyText
        ...FullProjectDataBodyVideo
        ...FullProjectDataBodyButton
        ...FullProjectDataBodyFooter
      }
    }
  }
}

`

export default ProjectTemplate