import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'
import styled from "@emotion/styled"

const Footerwrap = styled.div`
    font-family: 'Cormorant Garamond', serif;
    line-height: 20px;
    width: 700px;
    margin: 0px auto 60px auto;
    display: block;
    padding-bottom: 26px;
    color: rgba(0,0,0,.8);
`

export const Footer = ({ slice }) => {

  return (
    <Footerwrap>
        {slice.primary.footer.richText && <PrismicRichText field={slice.primary.footer.richText} />}
    </Footerwrap>
  )
}

export const query = graphql`
fragment FullProjectDataBodyFooter on PrismicFullProjectDataBodyFooter {
  id
  slice_type
  primary {
    footer {
      richText
    }
  }
}
`
