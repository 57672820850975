import React from 'react'
import { Link } from 'gatsby'
import styled from "@emotion/styled"
import { ArrowRight, ArrowLeft } from "phosphor-react"

const Pager = styled.nav`
width: 60vw;
margin: 0 auto;
margin-top: 60px;
@media (max-width: 676px) {
    width: 100%;
    margin: 0px auto;
}
> div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    a {
        display: block;
        border: none;
        opacity: 0.6;
        transition: all 0.3s ease-out;
        &:hover {
            opacity: 1;
        }
        svg {
            display: inline-block;
            vertical-align: middle;
            padding-top: 14px;
            top: 0;
        }
        h3 {
            border-top: solid 1px rgba(0,0,0,0.2);
            padding-top: 15px;
            font-family: "Freight Big Black Regular";
            line-height: 1em;
            font-size: 1.5rem;
            padding-bottom: 2px;
        }
        h4 {
            font-family: "FreightText Light Italic";
            font-size: 16px;
            line-height: 1.2em;
        }
    }
    > div {
        box-sizing: border-box;
        position: relative;
    }
}
`
const Previous = styled.div`
    border-right: solid 1px rgba(0,0,0,0.2);
    transition: all 0.3s ease;
    @media (max-width: 676px) {
        margin-right: inherit;
        margin-left: 20px;
    }
    a {
        margin-right: 25%;
        svg {
            position: absolute;
            left: -60px;
        }
    }
`
const Next = styled.div`
@media (max-width: 676px) {
    margin-left: inherit;
    margin-right: 20px;
}
transition: all 0.3s ease;
    a {
        margin-left: 25%;
        svg {
            position: absolute;
            right: -60px;
        }
    }
`

const Footernav = ({ nextLink, nextTitle, nextSubtitle, previousLink, previousTitle, previousSubtitle }) => {

    return (
        <Pager>
            <div>
            {previousLink ? <Previous className="prev"><Link to={previousLink}><ArrowLeft size={36} /><h3>{previousTitle}</h3><h4>{previousSubtitle}</h4></Link></Previous> : <div></div>}
            {nextLink ? <Next className="next"><Link to={nextLink}><h3>{nextTitle}</h3><h4>{nextSubtitle}</h4><ArrowRight size={36} /></Link></Next> : <div></div>}
            </div>
        </Pager>
    )
}

export default Footernav