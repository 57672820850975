import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { JustifiedGrid } from "@egjs/react-grid"
import { PrismicRichText } from '@prismicio/react'
import { AsteriskSimple } from "phosphor-react"
import styled from "@emotion/styled"
import { useMediaQuery } from 'react-responsive'

const Gridwrap = styled.div`
width: 77.5vw;
margin: 0 auto;
padding: 60px 0px;
> div {
    position: relative;
    > div {
        &:hover {
            cursor: pointer;
        }
        &::after {
            content: '+';
            background-color: rgb(127,127,127);
            color: #fff;
            font-weight: bold;
            position: absolute;
            bottom: 0;
            right: 0;
            height: 30px;
            width: 30px;
            text-align: center;
            line-height: 30px;
            opacity: 0;
            transition: all 0.3s ease;
        }
        &:hover::after {
            opacity: 1;
        }

    }
}
@media (max-width: 676px) {
  width: auto;
  margin: 0px 20px;
  .gatsby-image-wrapper {
    margin-bottom: 20px;
  }
}

`
const Caption = styled.div`
    width: 60vw;
    text-align: center;
    padding: 40px 0 0 0!important;
    color: rgba(0,0,0,0.5);
    font-size: .9125rem;
    margin: 0 auto;
    p, svg {
      display: inline;
      vertical-align: middle;
    }
    svg {
      padding-right: 10px;
    }
`

export const ImageGrid = ({ slice }) => {
  const maxcol = slice.primary.maximum_columns
  const isDesktop = useMediaQuery({ query: '(min-width: 600px)' })
  return (
      <Gridwrap>
        {isDesktop ? 
        <JustifiedGrid gap={10} columnRange={[1,maxcol]} isCroppedSize={true} sizeRange={[300, 1000]}>
            {slice.items.map(item => (
            <div key={item.images.url}>
            <GatsbyImage image={item.images.gatsbyImageData} alt="image" objectFit="contain" data-fancybox="images" />
            </div>
            ))}
        </JustifiedGrid>
        :
        <>
        {slice.items.map(item => (
          <div key={item.images.url}>
          <GatsbyImage image={item.images.gatsbyImageData} alt="image" objectFit="contain" data-fancybox="images" />
          </div>
          ))}
        </>
        }
        {slice.primary.caption.text && <Caption><AsteriskSimple size={14} color="#808080" weight="light" /><PrismicRichText field={slice.primary.caption.richText} /></Caption>}
      </Gridwrap>
  )
}

export const query = graphql`

fragment FullProjectDataBodyImageGrid on PrismicFullProjectDataBodyImageGrid {
    id
    slice_type
    primary {
      maximum_columns
      caption {
        richText
        text
      }
    }
    items {
      images {
        url
        gatsbyImageData
      }
    }
  }
`
